// import {
//   getCommonParamsOptions,
//   getGlobalParamsOptionsAsync,
//   getParamsOptionsAsync
// } from '@/utils/common'

export const baseViewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        disabled: true
      }
    },
    // {
    //   field: 'code',
    //   type: 'input',
    //   label: 'profile.code',
    //   otherOptions: {
    //     disabled: true
    //   }
    // },
    // {
    //   field: 'batchType',
    //   type: 'select',
    //   label: 'param.batch',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getCommonParamsOptions('batch_type'),
    //   otherOptions: {
    //     multiple: true,
    //     disabled: true
    //   }
    // },
    // {
    //   field: 'terminalId',
    //   type: 'select',
    //   label: 'param.terminal',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () =>
    //     getGlobalParamsOptionsAsync('terminalList', {
    //       isNeedPreFix: false,
    //       labelParams2: 'sn'
    //     }),
    //   otherOptions: {
    //     multiple: true,
    //     disabled: true
    //   }
    // },
    // {
    //   field: 'tenant',
    //   type: 'treeSelect',
    //   label: 'file.tenant',
    //   otherOptions: {
    //     props: {
    //       multiple: true
    //     },
    //     disabled: true
    //   },
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () => getParamsOptionsAsync('tenantList')
    // },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      colLayout: {
        xl: 16,
        lg: 16,
        md: 16,
        sm: 16,
        xs: 16
      },
      otherOptions: {
        disabled: true
      }
    }
  ],
  validateRules: {}
}
