import {
  getCommonParamsOptions,
  // getGlobalParamsOptionsAsync,
  getParamsOptionsAsync
} from '@/utils/common'

export const baseNewFormConfig = {
  labelWidth: '140px',
  itemStyle: {
    padding: '5px 20px'
  },
  formItems: [
    {
      field: 'name',
      type: 'input',
      label: 'general.name',
      otherOptions: {
        maxlength: 100
      }
    },
    // {
    //   field: 'code',
    //   type: 'input',
    //   label: 'profile.code'
    // },
    {
      field: 'batchType',
      type: 'select',
      label: 'param.batch',
      isResolveGlobalParams: true,
      options: [],
      isLinkageOptions: true,
      handler: () => getCommonParamsOptions('batch_type'),
      otherOptions: {
        multiple: true
      }
    },
    // {
    //   field: 'terminalId',
    //   type: 'select',
    //   label: 'param.terminal',
    //   isResolveGlobalParams: true,
    //   options: [],
    //   handler: () =>
    //     getGlobalParamsOptionsAsync('terminalList', {
    //       isNeedPreFix: false,
    //       labelParams2: 'sn'
    //     }),
    //   otherOptions: {
    //     multiple: true
    //   }
    // },
    {
      field: 'tenant',
      type: 'treeSelect',
      label: 'file.tenant',
      otherOptions: {
        props: {
          multiple: true
        }
      },
      isResolveGlobalParams: true,
      isLinkageOptions: true,
      options: [],
      handler: () => getParamsOptionsAsync('tenantList')
    },
    {
      field: 'description',
      type: 'textarea',
      label: 'general.description',
      colLayout: {
        xl: 24,
        lg: 24,
        md: 24,
        sm: 24,
        xs: 24
      },
      otherOptions: {
        maxlength: 500
      }
    }
  ],
  validateRules: {
    name: [
      {
        required: true,
        message: 'general.required',
        trigger: 'change'
      }
    ]
    // terminalId: [
    //   {
    //     required: true,
    //     message: 'general.required',
    //     trigger: 'change'
    //   }
    // ]
  }
}
